import {
    ExportFormat,
    ExportFormatValueDef,
} from '../components/api-docs/ApiSpecExport/ApiSpecExportInitialState';
import {
    PlatformConstantDef,
    PlatformType,
} from '../components/api-docs/Platforms/PlatformConstants';
import { FormValidatorDef } from '../types/form-validator';
import { ApiEntityDef } from '../types/portal-editor-settings';
import { UiStateDef } from '../types/ui-state';

const pixelToInt = (value: string) => {
    return parseFloat(value);
};

const valToPixel = (value: string) => {
    return value.toString() + 'px';
};

const formIsValid = (form: {
    state: FormValidatorDef;
    setState: React.Dispatch<React.SetStateAction<FormValidatorDef>>;
}): boolean => {
    if (!form.state.isSubmitted) {
        form.setState((previousState) => {
            return { ...previousState, isSubmitted: true };
        });
    }
    return Object.values(form.state.errors).filter((elem) => elem.length > 0).length === 0;
};

const resetForm = (form: {
    state: FormValidatorDef;
    setState: React.Dispatch<React.SetStateAction<FormValidatorDef>>;
}) => {
    form.setState((previousState) => {
        return {
            ...previousState,
            errors: {},
        };
    });
};

function getActiveApiEntity(
    apiEntityId: string,
    apiEntities: ApiEntityDef[]
): ApiEntityDef | undefined {
    const activeApiEntity = apiEntities.find(
        (apiEntity: ApiEntityDef) => apiEntity.id === apiEntityId
    );
    return activeApiEntity;
}

function getLoadingUiState(): UiStateDef {
    return {
        isLoading: true,
        isError: false,
        isSaving: false,
        statusCode: 0,
        message: '',
    };
}

function getDefaultUiState(): UiStateDef {
    return {
        isLoading: false,
        isError: false,
        isSaving: false,
        statusCode: 0,
        message: '',
    };
}

function getSavingUiState(): UiStateDef {
    return {
        isLoading: false,
        isError: false,
        isSaving: true,
        statusCode: 0,
        message: '',
    };
}

const filterAllowedPlatform = (
    platformConstants: PlatformConstantDef[],
    allowedPlatform: PlatformType[] | undefined
): PlatformConstantDef[] | undefined => {
    return (
        allowedPlatform &&
        platformConstants.filter(
            (p: PlatformConstantDef) => p.supported && allowedPlatform.includes(p.value)
        )
    );
};

function mergeAndOverrideArrays(mergeToArray: any, mergeFromArray: any, key: string): Array<any> {
    return mergeToArray
        .filter(
            (dataArrayOne: any) =>
                !mergeFromArray.find((dataArrayTwo: any) => dataArrayOne[key] === dataArrayTwo[key])
        )
        .concat(mergeFromArray);
}

const checkApiSpecExport = (exportFormats: ExportFormat): boolean => {
    let isExportCheck: boolean;

    for (const apiFormats in exportFormats) {
        const item = apiFormats as ExportFormatValueDef;

        isExportCheck = exportFormats[item].every((t) => !t.enabled);
        if (!isExportCheck) {
            break;
        }
    }
    return isExportCheck;
};

// Function to convert HEX to HSL
const hexToHSL = (hex: string): { h: number; s: number; l: number } => {
    // Remove the '#' if present
    hex = hex.replace('#', '');

    // Convert hex to RGB
    const r: number = parseInt(hex.substring(0, 2), 16) / 255;
    const g: number = parseInt(hex.substring(2, 4), 16) / 255;
    const b: number = parseInt(hex.substring(4, 6), 16) / 255;

    // Find min, max, and delta
    const max: number = Math.max(r, g, b);
    const min: number = Math.min(r, g, b);
    const delta: number = max - min;

    // Calculate Lightness
    const lightness: number = (max + min) / 2;

    // Calculate Saturation
    let saturation = 0;
    if (delta !== 0) {
        saturation = delta / (1 - Math.abs(2 * lightness - 1));
    }

    // Calculate Hue
    let hue = 0;
    if (delta !== 0) {
        if (max === r) {
            hue = ((g - b) / delta) % 6;
        } else if (max === g) {
            hue = (b - r) / delta + 2;
        } else {
            hue = (r - g) / delta + 4;
        }
        hue *= 60; // Convert to degrees
        if (hue < 0) hue += 360;
    }

    // Return HSL values as an object
    return {
        h: Math.round(hue),
        s: Math.round(saturation * 100),
        l: Math.round(lightness * 100),
    };
};

// Function to update the lightness of an HSL color
const changeLightness = (h: number, s: number, l: number, newLightness: number): string => {
    return hslToHex(`hsl(${h}, ${s}%, ${l + newLightness}%)`);
};

export const changeColorLightness = (hex: string, newLightness: number) => {
    const hsl = hexToHSL(hex);

    const updatedHSL: string = changeLightness(hsl.h, hsl.s, hsl.l, newLightness);

    return updatedHSL;
};

function hslToHex(hsl: string): string {
    // Regular expression to extract H, S, and L values from the hsl string
    const regex = /hsl\(\s*(\d+)\s*,\s*(\d+)%\s*,\s*(\d+)%\s*\)/;
    const match = hsl.match(regex);

    if (!match) {
        throw new Error('Invalid HSL string format');
    }

    // Extract H, S, L from the matched values
    const h = parseInt(match[1], 10); // Hue
    const s = parseInt(match[2], 10); // Saturation
    const l = parseInt(match[3], 10); // Lightness

    // Normalize saturation and lightness to [0, 1]
    const sNormalized = s / 100;
    const lNormalized = l / 100;

    // Calculate chroma, x, and m
    const c = (1 - Math.abs(2 * lNormalized - 1)) * sNormalized;
    const x = c * (1 - Math.abs(((h / 60) % 2) - 1));
    const m = lNormalized - c / 2;

    let r = 0;
    let g = 0;
    let b = 0;

    // Determine RGB values based on Hue
    if (h >= 0 && h < 60) {
        r = c;
        g = x;
        b = 0;
    } else if (h >= 60 && h < 120) {
        r = x;
        g = c;
        b = 0;
    } else if (h >= 120 && h < 180) {
        r = 0;
        g = c;
        b = x;
    } else if (h >= 180 && h < 240) {
        r = 0;
        g = x;
        b = c;
    } else if (h >= 240 && h < 300) {
        r = x;
        g = 0;
        b = c;
    } else if (h >= 300 && h < 360) {
        r = c;
        g = 0;
        b = x;
    }

    // Convert RGB to [0, 255] range
    const red = Math.round((r + m) * 255);
    const green = Math.round((g + m) * 255);
    const blue = Math.round((b + m) * 255);

    // Convert each color component to a 2-digit hexadecimal value
    const toHex = (value: number): string => value.toString(16).padStart(2, '0');

    // Combine RGB components into HEX format
    return `#${toHex(red)}${toHex(green)}${toHex(blue)}`;
}

export const utilityFunctions = {
    pixelToInt,
    valToPixel,
    formIsValid,
    resetForm,
    getActiveApiEntity,
    getLoadingUiState,
    getDefaultUiState,
    getSavingUiState,
    filterAllowedPlatform,
    mergeAndOverrideArrays,
    checkApiSpecExport,
};
